import React from 'react';
import parse from 'html-react-parser';

import Container from '../Container';
import Row from '../Container/row';
import Col from '../Container/column';
import PageSubtitle from '../Subpage/subtitle';

import withLightbox from '../Lightbox/settings';

import { BorderRadius } from '../../utils/variables';

import './style.css';

import loadLibrary from '../Loadable/library';
import loadComponents from '../Loadable';

const Img = loadLibrary('gatsby-image');

const H3 = loadComponents('h3');
const H4 = loadComponents('h4');
const LightboxWrapper = loadComponents('lightbox-wrapper');
const ButtonHidden = loadComponents('button-hidden');

class TeamBlock extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClick = data => {
    if (this._isMounted) {
      const { openLightbox } = this.props;
      this.setState(
        {
          selected: data,
        },
        () => {
          openLightbox();
        },
      );
    }
  };

  render() {
    const { selected } = this.state;
    const { title, data, showLightbox, closeLightbox } = this.props;
    return (
      <div className="subpageComponent teamMembers" style={outerStyle}>
        {title && <PageSubtitle title={title} />}
        <Row>
          {data.map((item, index) => {
            const key = `teamMember${index}`;
            return (
              <Col
                className="item"
                xl={{ width: '33.333%' }}
                md={{ width: `50%` }}
                key={key}
              >
                <div style={imageStyle}>
                  <ButtonHidden
                    onClick={() =>
                      this.handleClick({
                        image:
                          item.image.localFile.childImageSharp.gatsbyImageData,
                        name: item.content.name,
                        title: item.content.jobTitle,
                        bio: item.content.bio,
                      })
                    }
                    disabled={item.content.bio === null}
                  >
                    <Img
                      image={
                        item.image.localFile.childImageSharp.gatsbyImageData
                      }
                      alt={item.content.name}
                    />
                  </ButtonHidden>
                </div>
                <H3 margin="20px">{item.content.name}</H3>
                <p style={jobStyle}>{item.content.jobTitle}</p>
              </Col>
            );
          })}
        </Row>
        <LightboxWrapper
          showLightbox={showLightbox}
          closeLightbox={closeLightbox}
          noLoader
        >
          {selected && (
            <Container>
              {selected.image && (
                <Row>
                  <div className="lightboxImage" style={lightboxImage}>
                    <Img image={selected.image} alt={selected.name} />
                  </div>
                  <div
                    className="lightboxContent"
                    style={{
                      padding: `0 0.9375rem`,
                      margin: `0 0 7.5rem`,
                    }}
                  >
                    <H4 margin="20px" colour="#fff">
                      {selected.name}
                    </H4>
                    <p style={jobStyle}>{selected.title}</p>
                    <div>{parse(selected.bio)}</div>
                  </div>
                </Row>
              )}
            </Container>
          )}
        </LightboxWrapper>
      </div>
    );
  }
}

export default withLightbox(TeamBlock);

const outerStyle = {
  width: `100%`,
  zIndex: 50,
};

const imageStyle = {
  maxWidth: `100%`,
  borderRadius: BorderRadius,
  overflow: `hidden`,
  marginBottom: `2.8125rem`,
  cursor: `pointer`,
};

const jobStyle = {
  fontStyle: `italic`,
};

const lightboxImage = {
  padding: `0 0.9375rem`,
  position: `relative`,
  margin: `0 auto 2.8125rem`,
};
